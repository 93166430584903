<template>
  <div>
    <PageLoader v-bind:storage="appLoading" />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="snackbar" color="#3CB043" right :timeout="3000">
      <v-layout wrap>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="snackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center align-center fill-height py-16>
      <v-flex xs12 sm8 md5 lg4 align-self-center pt-16>
        <v-card rounded="xl" outlined elevation="0">
          <v-layout wrap justify-center>
            <v-flex xs6 pt-8>
              <span
                style="
                 font-family: opensansbold;
                  font-size: 25px;
                  color: #427D2D;
                "
                >Reset Password
              </span>
            </v-flex>
          </v-layout>
          <v-layout wrap justify-center>
            <v-flex xs10 sm10 text-left>
              <v-layout wrap>

                <v-flex xs12 sm12 text-left>
                  <span style="font-family: opensansregular; font-size: 14px"
                    >OTP</span
                  >
                  <v-otp-input
                       length="6"   v-model="code"
                     ></v-otp-input>
                </v-flex>

                <v-flex xs12 sm12 text-left>
                  <span style="font-family: opensansregular; font-size: 14px"
                    >User Name</span
                  >
                  <v-text-field
               readonly
                     v-model="userName"
             
                    outlined
                    dense
                  >
                  </v-text-field>
                </v-flex>

                <v-flex xs12 sm12 text-left>
                  <span style="font-family: opensansregular; font-size: 14px"
                    >Phone</span
                  >
                  <v-text-field
               readonly
                     v-model="phone"
             
                    outlined
                    dense
                  >
                  </v-text-field>
                </v-flex>


                


                <v-flex xs12 sm12 text-left>
                  <span style="font-family: opensansregular; font-size: 14px"
                    >New password</span
                  >
                  <v-text-field
                    :rules="[rules.required, rules.min]"
                    v-model="password"
                    type="password"
                    outlined
                    dense
                  >
                  </v-text-field>
                </v-flex>

                <v-flex xs12 sm12 text-left>
                  <span style="font-family: opensansregular; font-size: 14px"
                    >Confirm password</span
                  >
                  <v-text-field
                    outlined
                    dense
                    type="password"
                    :rules="[rules.required, rules.min, rules.check]"
                    v-model="cpassword"
                  >
                  </v-text-field>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs10 sm10 pt-5 pb-10 v-if="password == cpassword">
              <v-btn block color="#427D2D" dark @click="resetPassword()">
                <span
                  style="
                    font-size: 14px;
                    font-family: poppinssemibold;
                    text-transform: none;
                  "
                  >Reset Password</span
                >
              </v-btn>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
// import VieOtpInput from "@bachdgvn/vue-otp-input";
import axios from "axios";
export default {
  components: {
    // VieOtpInput,
  },
  data() {
    return {
      appLoading: false,
      ServerError: false,
      snackbar: false,
      timeout: 5000,
      msg: null,
      msg2: null,
     // phone: null,
      code: null,
      timeLeft: "00:00",
      intervalTimer: null,
      otpexpired: false,
      phone: this.$route.query.phone || '',
      userName: this.$route.query.userName || '',
      status: "",
      password: "",
      cpassword: "",
      // userName:"",
      rules: {
        required: (value) => !!value || "Required.",
        min: (value) => value.length >= 6 || "Min 6 characters",
       // pincode: (value) => value.length == 6 || "Please provide valid pincode",
        code: (value) => value.length == 4 || "Please provide valid otp",
        check: (confirmation) =>
          confirmation === this.password || "Passwords do not match",
      },
    };
  },
  methods: {
    // validateOTP(value) {
    //   this.otp = value;
    // },
    resetPassword() {
      var userData = {};
     // userData["resetToken"] = this.$route.query.resetToken;
      userData["phone"] = this.phone;
      userData["code"] = this.code;
      userData["password"] = this.password;
      userData["cpassword"] = this.cpassword;
      userData["userName"] = this.userName;
      
      

      this.appLoading = true;
      axios({
        method: "POST",
        url: "/users/resetotp/confirm/officials",
        data: userData,
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.status = response.data.status;
            this.msg = response.data.msg;
            this.snackbar = true;
            this.$router.push("/login");
          } else {
            this.msg = response.data.msg;
            this.snackbar = true;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
<style scoped>
.centered-input >>> input {
  text-align: center;
}
</style>
  